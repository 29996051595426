.client-review-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 3%;
    margin-bottom: 3%;
    padding: 3%;
    background: #FFFFFF;
    border: 1px solid #3DC9A1;
    border-radius: 30px 30px 30px 0px;
}

.client-review-container:hover {
    background-color: #3DC9A1;
}

.client-review {
    padding: 3%;
}

.cleints-personal {
    display: flex;
    flex-direction: row;
    padding: 1%;
    justify-content: space-evenly;
}

.client-img {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1%;
    justify-content: space-between;
}

.images-client1 {
    width: 30%;
    margin: 5%;
}

.name-client1 {
    width: 70%;
    margin: 5%;
}

.star-section {
    width: 100%;
    margin-top: 7%;
    margin-left: 14.8%;
    position: absolute;
}


.rating-section {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-right: 4%;
    align-items:center;
}