.services-container {
    margin-top: 5%;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    padding: 2%;
    display: flex;   
}

@media screen and (max-width:425px) {
    .services-container {
        flex-direction: column;
    }
}

.clients-header {
    display: flex;
    flex-direction: row;
}

.client-test {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.client-desc {
    width: 20%;
}

@media screen and (max-width:768px) { 
    .client-desc {
        margin-top: 15%;
    }    
}


.services-left-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.services-right-container {
   /* display: flex; */
   flex-direction: row;
}

.client-says {
    display: flex;
    flex-direction: column;
    padding: 5%;
}

.testimonials{
    width: 163px;
    height: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 167.5%;
    letter-spacing: 0.06em;
    color: #3DC9A1;
    text-align: left;
}

.client-says-header {
    display: flex;
    flex-direction: row;
}

.heading-client-says {
    width: 100%;
}

.what-client-says{
    width: 300px;
    height: 144px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    color: #208C84;
}

@media screen and (max-width: 768px) {
    .what-client-says {
        width: 300px;
        font-size: 35px;
        line-height: 55px;
    }
}

.quote-div {
    display:flex ;
    justify-content: flex-end;
    align-items: flex-start;
    margin-right: 30%;
}


.seeMore {
    width: 389px;
    height: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 167.5%;
    color: #208C84;
}


.services-right-container  {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.first-column {
    width: 100%;
}


.client-container {
    margin-left: 3%;
    margin-right: 3%;
}

