.mainContainer {
    margin-top: 20%;
    margin-bottom: 5%;
}

.innerContainer {
    display: flex;
    /* border: 1px solid black; */
    justify-content: space-evenly;
}

.left-Container {
    background: #C2DDE4;
    height: 520px;
    width: 650px;
    background-image: url('../images/group-570.png');
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width:425px) { 
    .left-Container {
        height: 380px;
    }
}
    

.right-Container {
    background: #EDEEC6;
    width: 650px;
    height: 520px;
    background-image: url('../images/group-571.png');
    background-size: contain;
    background-repeat: no-repeat;
}

@media screen and (max-width:425px) { 
    .right-Container {
        height: 380px;
    }
}

.pro-services {
    height: 72px;
    width: 315px;
    border-radius: nullpx;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 72px;
    color: #156075;
    margin-top: 20%;
    margin-left: 5%;
}


.appointment{
    /* position: absolute; */
    width: 607px;
    height: 78px;
    margin-left: 5%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 167.5%;
    /* or 33px */
    color: #191919;
}

@media screen and (max-width:425px) {
    .appointment {
        width: 250px;
        font-size: 12px;
    }
}

.appointment-grp {
    margin-left: 3%;
    width: 300%;
    height: 200%;
    font-size: 100%;
    font-family: 'Poppins';
    font-style: normal;
}

@media screen and (max-width: 325px ){
    .appointment-grp {
        width: 120%;
        height: 150%;
        font-size: 20%;
    }
}

@media screen and (max-width: 575.98px ){
    .appointment-grp {
        width: 120%;
        height: 150%;
        font-size: 65%;
    }
}

.right-sign {
    width: 15%;
    padding: 0.5%;
    height: fit-content;
    display: flex;
    align-items: flex-start;
}


@media screen and (max-width: 425.98px ) {
    .right-sign {
        width: 8%;
        height: fit-content;
    }  
}

.appointment-text-final {
    display: flex;
    flex-direction: column;
}



.appointment-sign-text {
    display: flex;
    flex-direction: row;
    /* padding: 1%; */
    width: 200%;
}


.appointment-text {
    height: 40%;
    width: 40%;
    margin-left: 5%;
}

.arrow-img {
    margin-top: 10%;
    height: 50%;
    width: 50%;
}

.arrow-img:hover {
    transform: scale(1.2);
}



.legal-header {
    width: 400px;
    height: 100px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 50px;
    margin-top: 13%;
    margin-left: 5%;
    color: #3F3309;
}