.container main-are-you {
   background: #5d9cac;
}


.left-Container-data {
    background: #C2DDE4;
    border-radius: 5px;
    background-size: 720px 480px;
    background-repeat: no-repeat;
    margin: 10px
}

.right-Container-data {
    background: #EDEEC6;
    border-radius: 5px;
    background-size: 640px 450px;
    background-repeat: no-repeat;
    margin: 10px
}

.left-Container-1 {
    background: #E5EAED;
}

.right-Container-1 {
    background: #E5EAED;
}


.Heading {
    width: 231px;
    height: 60px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    text-align: center;
    color: #191919;
    margin-left: 55px
}

 
.Headingsub {

    width: 219px;
    height: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #191919;
    margin-left: 66px
}

.lableclass {
    width: 49px;
    height: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #191919;

}

.Inputclass {
    /* width: 444px;
    height: 35px; */
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #191919;
    background: transparent;
    border: none;
    border-bottom: 1px solid #000000;

}

.eye {
    width: 23px;
    margin-left: -44px;
    margin-bottom: 18px;

}

.f_pasword {
    /* margin-left: 326px; */
    color: #3DC9A1
}

.prov-services_conatiner-1 {
    height: 930px;
}

form.form-getstart {
    padding: 4px -6px 0px;
    margin-left: 42px;
}

.row.innerContainer {
    padding: 72px 6px 0px;
}

.Sign_button {
    color: white;
    width: 210px;
    height: 68px;
    margin-left: 28px;
    background: #208C84;
}


.img-container{
    padding: 50px 16px;
}
