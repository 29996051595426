.mainContainer-data {
    margin-top: 5%;
    margin-bottom: 5%;
}

.innerContainer-data {
    display: flex;
    justify-content: center;
}
.left-Container-data {
    height: 330px;
    background: #C2DDE4;
    border-radius: 5px;
    background-repeat: no-repeat;
    margin: 10px
}

.right-Container-data {
    height: 330px;
    background: #ece9e9;
    border-radius: 5px;
    background-repeat: no-repeat;
    margin: 10px
}

.register-logo{
    width: 61px;
height: 61px;

}
.register-logo-data{
    width: 61px;
height: 61px;
}
.register-logo-data2{
    width: 61px;
    height: 61px;
    margin-top: 20px    
}
.register-logo-data3{
    width: 61px;
    height: 61px;
    margin-top: 20px    
}
.why-formate{
    margin-left: 76px;
    margin-top: 50px;
}
.why-heading{
    width: 509px;
height: 60px;

font-family: 'Poppins';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 60px;
color: #156075;
}

.why-subheading{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
color: #191919;
}