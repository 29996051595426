
.first-container-test{
    color: #208C84;
    font-size: 40px;
    font-family: 'Poppins';
}
.container1{
    padding: 100px;
}
 
section.we_coonect_section {
    background: #f4fefa;
   
}

.features_ask_and_book{
    background: #f4fefa;
    height: 800px;

}

.second_container_para{
    margin-top: 30px;
    font-size: 24px;
    font-family: 'Poppins';
}

.cstm_img_btn {
    text-align: left;
    width: 200px;
}
.cstm_col_we_connect{
    margin-top: 10px;
}

.cstm_col_we_connect {
    margin-top: 80px;
}

img.logo_hero_section {
    width: 228px;
    margin-bottom: 36px;
    text-align: left;
}

.btn_hero_long{
    width: 10px;
}

.btn_hero_long {
    width: 208px;
}
 

 .cstm_img_btn {
    width: 208px;
    text-align: left;
 }
 .heading-h2{
    text-align: inherit;
 }
 .img-wrapper{
    overflow:hidden;
  }
  .hover-zoom:hover {
    transform: scale(1.2);
  }