@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

p,
span,
h1,
h2 {
  font-family: "Poppins";
  font-style: normal;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 50%;
}

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #fff;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 30px;
  color: #2a2a2a;
}

img {
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: "Poppins", sans-serif;
}

::selection {
  background: #03a4ed;
  color: #fff;
}

::-moz-selection {
  background: #03a4ed;
  color: #fff;
}

@media (max-width: 991px) {
  html,
  body {
    overflow-x: hidden;
  }

  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .mobile-bottom-fix {
    margin-bottom: 30px;
  }

  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }
}

.page-section {
  margin-top: 120px;
}

.section-heading h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: #2a2a2a;
  font-weight: 700;
  letter-spacing: 0.25px;
  position: relative;
  z-index: 2;
  line-height: 44px;
}

.section-heading h2 em {
  font-style: normal;
  color: #03a4ed;
}

.section-heading h2 span {
  color: #fe3f40;
}

.main-blue-button a {
  display: inline-block;
  background-color: #03a4ed;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
}

.main-red-button a {
  display: inline-block;
  background-color: #fe3f40;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
}

.main-white-button a {
  display: inline-block;
  background-color: #fff;
  font-size: 15px;
  font-weight: 400;
  color: #fe3f40;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
}

/* 
---------------------------------------------
header
--------------------------------------------- 
*/

.background-header {
  background-color: #fff !important;
  height: 80px !important;
  position: fixed !important;
  top: 0px;
  left: 0px;
  right: 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15) !important;
}

.background-header .logo,
.background-header .main-nav .nav li a {
  color: #1e1e1e !important;
}

.background-header .main-nav .nav li:hover a {
  color: #fe3f40 !important;
}

.background-header .nav li a.active {
  color: #fe3f40 !important;
}

.header-area {
  background-color: #208c84;
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  height: 66px;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.header-area .main-nav {
  min-height: 80px;
  background: transparent;
}

.header-area .main-nav .logo {
  line-height: 100px;
  color: #fff;
  position: absolute;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  float: left;
  transition: all 0.3s ease 0s;
  left: 33px;
}

.header-area .main-nav .logo h4 {
  font-size: 24px;
  font-weight: 600;
  text-transform: capitalize;
  color: #03a4ed;
  line-height: 69px;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.logo h4 span {
  color: #fe3f40;
}

.background-header .main-nav .logo h4 {
  line-height: 80px;
}

.background-header .main-nav .nav {
  margin-top: 20px !important;
}

.header-area .main-nav .nav {
  float: right;
  margin-top: 30px;
  margin-right: 68px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 999;
}

.header-area .main-nav .nav li {
  padding-left: 20px;
  padding-right: 20px;
}

.header-area .main-nav .nav li:last-child {
  padding-right: 0px;
}

.header-area .main-nav .nav li a {
  display: block;
  font-weight: 500;
  font-size: 15px;
  color: white;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  height: 40px;
  /* line-height: 40px; */
  border: transparent;
  /* letter-spacing: 1px; */
}

.header-area .main-nav .nav li a {
  color: white;
}

.header-area .main-nav .nav li:hover a,
.header-area .main-nav .nav li a.active {
  color: white !important;
}

.background-header .main-nav .nav li:hover a,
.background-header .main-nav .nav li a.active {
  color: #fe3f40 !important;
  opacity: 1;
}

.header-area .main-nav .nav li.submenu {
  position: relative;
  padding-right: 30px;
}

.header-area .main-nav .nav li.submenu:after {
  font-family: FontAwesome;
  content: "\f107";
  font-size: 12px;
  color: #2a2a2a;
  position: absolute;
  right: 18px;
  top: 12px;
}

.background-header .main-nav .nav li.submenu:after {
  color: #2a2a2a;
}

.header-area .main-nav .nav li.submenu ul {
  position: absolute;
  width: 200px;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  overflow: hidden;
  top: 50px;
  opacity: 0;
  transform: translateY(+2em);
  visibility: hidden;
  z-index: -1;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.header-area .main-nav .nav li.submenu ul li {
  margin-left: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.header-area .main-nav .nav li.submenu ul li a {
  opacity: 1;
  display: block;
  background: #f7f7f7;
  color: #2a2a2a !important;
  padding-left: 20px;
  height: 40px;
  line-height: 40px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #eee;
}

.header-area .main-nav .nav li.submenu ul li a:hover {
  background: #fff;
  color: #fe3f40 !important;
  padding-left: 25px;
}

.header-area .main-nav .nav li.submenu ul li a:hover:before {
  width: 3px;
}

.header-area .main-nav .nav li.submenu:hover ul {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  transform: translateY(0%);
  transition-delay: 0s, 0s, 0.3s;
}

.header-area .main-nav .menu-trigger {
  cursor: pointer;
  display: block;
  position: absolute;
  top: 33px;
  width: 32px;
  height: 40px;
  text-indent: -9999em;
  z-index: 99;
  right: 40px;
  display: none;
}

.background-header .main-nav .menu-trigger {
  top: 23px;
}

.header-area .main-nav .menu-trigger span,
.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
}

.background-header .main-nav .menu-trigger span,
.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  background-color: #1e1e1e;
  display: block;
  position: absolute;
  width: 30px;
  height: 2px;
  left: 0;
  width: 75%;
}

.background-header .main-nav .menu-trigger span:before,
.background-header .main-nav .menu-trigger span:after {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger span:before,
.header-area .main-nav .menu-trigger span:after {
  content: "";
}

.header-area .main-nav .menu-trigger span {
  top: 16px;
}

.header-area .main-nav .menu-trigger span:before {
  -moz-transform-origin: 33% 100%;
  -ms-transform-origin: 33% 100%;
  -webkit-transform-origin: 33% 100%;
  transform-origin: 33% 100%;
  top: -10px;
  z-index: 10;
}

.header-area .main-nav .menu-trigger span:after {
  -moz-transform-origin: 33% 0;
  -ms-transform-origin: 33% 0;
  -webkit-transform-origin: 33% 0;
  transform-origin: 33% 0;
  top: 10px;
}

.header-area .main-nav .menu-trigger.active span,
.header-area .main-nav .menu-trigger.active span:before,
.header-area .main-nav .menu-trigger.active span:after {
  background-color: transparent;
  width: 100%;
}

.header-area .main-nav .menu-trigger.active span:before {
  -moz-transform: translateY(6px) translateX(1px) rotate(45deg);
  -ms-transform: translateY(6px) translateX(1px) rotate(45deg);
  -webkit-transform: translateY(6px) translateX(1px) rotate(45deg);
  transform: translateY(6px) translateX(1px) rotate(45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:before {
  background-color: #1e1e1e;
}

.header-area .main-nav .menu-trigger.active span:after {
  -moz-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -ms-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  -webkit-transform: translateY(-6px) translateX(1px) rotate(-45deg);
  transform: translateY(-6px) translateX(1px) rotate(-45deg);
  background-color: #1e1e1e;
}

.background-header .main-nav .menu-trigger.active span:after {
  background-color: #1e1e1e;
}

.header-area.header-sticky {
  min-height: 80px;
}

.header-area .nav {
  margin-top: 30px;
}

.header-area.header-sticky .nav li a.active {
  color: white;
}

@media (max-width: 1200px) {
  .header-area .main-nav .nav li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .header-area .main-nav:before {
    display: none;
  }
}

@media (max-width: 992px) {
  .header-area .main-nav .nav li:last-child,
  .background-header .main-nav .nav li:last-child {
    display: none;
  }

  .header-area .main-nav .nav li:nth-child(6),
  .background-header .main-nav .nav li:nth-child(6) {
    padding-right: 0px;
  }
}

@media (max-width: 767px) {
  .background-header .main-nav .nav {
    margin-top: 80px !important;
  }

  .header-area .main-nav .logo {
    color: #1e1e1e;
  }

  .header-area.header-sticky .nav li a:hover,
  .header-area.header-sticky .nav li a.active {
    color: white !important;
    opacity: 1;
  }

  .header-area.header-sticky .nav li.search-icon a {
    width: 100%;
  }

  .header-area {
    background-color: #208c84 !important;
    padding: 0px 15px;
    height: 100px;
    box-shadow: none;
    text-align: center;
  }

  .header-area .container {
    padding: 0px;
  }

  .header-area .logo {
    margin-left: 30px;
  }

  .header-area .menu-trigger {
    display: block !important;
  }

  .header-area .main-nav {
    overflow: hidden;
  }

  .header-area .main-nav .nav {
    float: none;
    width: 100%;
    display: none;
    -webkit-transition: all 0s ease 0s;
    -moz-transition: all 0s ease 0s;
    -o-transition: all 0s ease 0s;
    transition: all 0s ease 0s;
    margin-left: 0px;
  }

  .background-header .nav {
    margin-top: 80px;
  }

  .header-area .main-nav .nav li:first-child {
    border-top: 1px solid #eee;
  }

  .header-area.header-sticky .nav {
    margin-top: 100px;
  }

  .header-area .main-nav .nav li {
    width: 100%;
    background: #fff;
    border-bottom: 1px solid #e7e7e7;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .header-area .main-nav .nav li a {
    height: 50px !important;
    line-height: 50px !important;
    padding: 0px !important;
    border: none !important;
    background: #f7f7f7 !important;
    color: #191a20 !important;
  }

  .header-area .main-nav .nav li a:hover {
    background: #eee !important;
    color: #fe3f40 !important;
  }

  .header-area .main-nav .nav li.submenu ul {
    position: relative;
    visibility: inherit;
    opacity: 1;
    z-index: 1;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
    top: 0px;
    width: 100%;
    box-shadow: none;
    height: 0px;
  }

  .header-area .main-nav .nav li.submenu ul li a {
    font-size: 12px;
    font-weight: 400;
  }

  .header-area .main-nav .nav li.submenu ul li a:hover:before {
    width: 0px;
  }

  .header-area .main-nav .nav li.submenu ul.active {
    height: auto !important;
  }

  .header-area .main-nav .nav li.submenu:after {
    color: #3b566e;
    right: 25px;
    font-size: 14px;
    top: 15px;
  }

  .header-area .main-nav .nav li.submenu:hover ul,
  .header-area .main-nav .nav li.submenu:focus ul {
    height: 0px;
  }
}

@media (min-width: 767px) {
  .header-area .main-nav .nav {
    display: flex !important;
  }
}

@media (max-width: 768px) {
  .header-area .main-nav .nav {
    display: none !important;
  }
}

/* 
---------------------------------------------
preloader
--------------------------------------------- 
*/

.js-preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  z-index: 9999;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.js-preloader.loaded {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

@-webkit-keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@keyframes dot {
  50% {
    -webkit-transform: translateX(96px);
    transform: translateX(96px);
  }
}

@-webkit-keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

@keyframes dots {
  50% {
    -webkit-transform: translateX(-31px);
    transform: translateX(-31px);
  }
}

.preloader-inner {
  position: relative;
  width: 142px;
  height: 40px;
  background: #fff;
}

.preloader-inner .dot {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 12px;
  left: 15px;
  background: #fe3f40;
  border-radius: 50%;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-animation: dot 2.8s infinite;
  animation: dot 2.8s infinite;
}

.preloader-inner .dots {
  -webkit-transform: translateX(0);
  transform: translateX(0);
  margin-top: 12px;
  margin-left: 31px;
  -webkit-animation: dots 2.8s infinite;
  animation: dots 2.8s infinite;
}

.preloader-inner .dots span {
  display: block;
  float: left;
  width: 16px;
  height: 16px;
  margin-left: 16px;
  background: #fe3f40;
  border-radius: 50%;
}

/* 
---------------------------------------------
Banner Style
--------------------------------------------- 
*/

.main-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 226px 0px 120px 0px;
  position: relative;
  overflow: hidden;
}

.main-banner:after {
  content: "";
  background-image: url(../images/baner-dec-left.png);
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 100px;
  width: 193px;
  height: 467px;
}

.main-banner:before {
  content: "";
  background-image: url(../images/baner-dec-right.png);
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 100px;
  width: 98px;
  height: 290px;
}

.main-banner .left-content {
  margin-right: 45px;
}

.main-banner .left-content h6 {
  text-transform: uppercase;
  font-size: 18px;
  color: #fe3f40;
  margin-bottom: 15px;
}

.main-banner .left-content h2 {
  font-size: 50px;
  font-weight: 700;
  color: #2a2a2a;
  line-height: 72px;
}

.main-banner .left-content h2 em {
  color: #03a4ed;
  font-style: normal;
}

.main-banner .left-content h2 span {
  color: #fe3f40;
}

.main-banner .left-content p {
  margin: 20px 0px;
}

.main-banner .left-content form {
  margin-top: 30px;
  width: 470px;
  height: 66px;
  position: relative;
}

.main-banner .left-content form button {
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
  background-color: #fff;
  font-size: 15px;
  font-weight: 500;
  color: #fe3f40;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
  outline: none;
  border: none;
}

.main-banner .left-content form input {
  width: 470px;
  height: 66px;
  background-color: #03a4ed;
  border-radius: 33px;
  border: none;
  outline: none;
  padding: 0px 25px;
  color: #fff;
  letter-spacing: 0.25px;
  font-size: 15px;
  font-weight: 300;
}

.main-banner .left-content form input::placeholder {
  color: #fff;
}

/* 
---------------------------------------------
About Us Style
--------------------------------------------- 
*/

#about {
  margin-top: 120px;
}

.about-us .left-image {
  margin-right: 45px;
}

.about-us .services .item {
  margin-bottom: 30px;
}

.about-us .services .item .icon {
  float: left;
  margin-right: 25px;
}

.about-us .services .item .icon img {
  max-width: 70px;
}

.about-us .services .item h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 12px;
}

.about-us .services .item p {
  color: #fff;
}

/* 
---------------------------------------------
Services Style
--------------------------------------------- 
*/

.our-services {
  margin-top: 0px;
  padding-top: 120px;
}

.our-services .left-image {
  margin-right: 45px;
}

.our-services .section-heading h2 {
  margin-right: 100px;
}

.our-services .section-heading p {
  margin-top: 30px;
  margin-bottom: 60px;
}

.our-services .progress-skill-bar {
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}

.our-services .progress-skill-bar span {
  position: absolute;
  top: 0;
  font-size: 18px;
  font-weight: 600;
  color: #03a4ed;
}

.our-services .first-bar span {
  left: 69%;
}

.our-services .second-bar span {
  left: 81%;
}

.our-services .third-bar span {
  left: 88%;
}

.our-services .progress-skill-bar h4 {
  font-size: 18px;
  font-weight: 700;
  color: #2a2a2a;
  margin-bottom: 14px;
}

.our-services .progress-skill-bar .full-bar {
  width: 100%;
  height: 6px;
  border-radius: 3px;
  background-color: #f7eff1;
  position: relative;
  z-index: 1;
}

.our-services .progress-skill-bar .filled-bar {
  background: rgb(255, 77, 30);
  background: linear-gradient(
    105deg,
    rgba(255, 77, 30, 1) 0%,
    rgba(255, 44, 109, 1) 100%
  );
  height: 6px;
  border-radius: 3px;
  margin-bottom: -6px;
  position: relative;
  z-index: 2;
}

.our-services .first-bar .filled-bar {
  width: 71%;
}

.our-services .second-bar .filled-bar {
  width: 83%;
}

.our-services .third-bar .filled-bar {
  width: 90%;
}

/* 
---------------------------------------------
Portfolio
--------------------------------------------- 
*/

.our-portfolio {
  padding-top: 120px;
  margin-top: 0px;
}

.our-portfolio .section-heading h2 {
  text-align: center;
  margin: 0px 90px 0px 90px;
  margin-bottom: 120px;
  position: relative;
  z-index: 1;
}

.our-portfolio .item {
  position: relative;
}

.our-portfolio .item:hover .hidden-content {
  top: -100px;
  opacity: 1;
  visibility: visible;
}

.our-portfolio .item:hover .showed-content {
  top: 90px;
}

.our-portfolio .hidden-content {
  background: rgb(255, 77, 30);
  background: linear-gradient(
    105deg,
    rgba(255, 77, 30, 1) 0%,
    rgba(255, 44, 109, 1) 100%
  );
  padding: 30px;
  border-radius: 20px;
  text-align: center;
  opacity: 0;
  top: 0;
  visibility: hidden;
  position: absolute;
  z-index: 2;
  transition: all 0.5s;
}

.our-portfolio .hidden-content:after {
  width: 20px;
  height: 20px;
  position: absolute;
  background: rgb(255, 77, 30);
  content: "";
  left: 50%;
  bottom: -8px;
  margin-left: -5px;
  transform: rotate(45deg);
  background: linear-gradient(
    105deg,
    rgba(255, 52, 69, 1) 0%,
    rgba(255, 51, 78, 1) 100%
  );
  z-index: -1;
}

.our-portfolio .hidden-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 20px;
}

.our-portfolio .hidden-content p {
  color: #fff;
}

.our-portfolio .showed-content {
  top: 0px;
  position: relative;
  z-index: 3;
  background-color: #fff;
  text-align: center;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

.our-portfolio .showed-content img {
  max-width: 100px;
}

/* 
---------------------------------------------
Blog
--------------------------------------------- 
*/

.our-blog {
  position: relative;
  margin-top: 80px;
  padding-top: 120px;
}

.our-blog .section-heading h2 {
  margin-right: 180px;
}

.our-blog .top-dec {
  text-align: right;
  margin-top: -80px;
}

.our-blog .top-dec img {
  max-width: 270px;
}

.our-blog .left-image {
  position: relative;
}

.our-blog .left-image img {
  border-radius: 20px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.our-blog .left-image .info {
  position: relative;
}

.our-blog .left-image .info .inner-content {
  background-color: #fff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-right: 75px;
  position: absolute;
  margin-top: -100px;
  padding: 30px;
}

.our-blog .left-image ul li {
  display: inline-block;
  font-size: 15px;
  color: #afafaf;
  font-weight: 300;
  margin-right: 20px;
}

.our-blog .left-image ul li:last-child {
  margin-right: 0px;
}

.our-blog .left-image ul li i {
  color: #ff4d61;
  font-size: 16px;
  margin-right: 8px;
}

.our-blog .left-image h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 20px 0px 15px 0px;
}

.our-blog .left-image .info .main-blue-button {
  position: absolute;
  bottom: -80px;
  left: 0;
}

.our-blog .right-list {
  margin-left: 30px;
}

.our-blog .right-list ul li {
  display: inline-flex;
  width: 100%;
  margin-bottom: 30px;
}

.our-blog .right-list .left-content {
  margin-right: 45px;
}

.our-blog .right-list .left-content span {
  font-size: 15px;
  color: #afafaf;
  font-weight: 300;
}

.our-blog .right-list .left-content span i {
  color: #ff4d61;
  font-size: 16px;
  margin-right: 8px;
}

.our-blog .right-list .left-content h4 {
  font-size: 20px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 20px 0px 15px 0px;
}

.our-blog .right-list .right-image img {
  width: 250px;
  border-radius: 20px;
}

/* 
---------------------------------------------
contact
--------------------------------------------- 
*/

.contact-us .section-heading h2,
.contact-us .section-heading h2 em,
.contact-us .section-heading h2 span {
  color: #fff;
}

.contact-us .section-heading p {
  color: #fff;
  margin-top: 30px;
}

.phone-info {
  margin-top: 40px;
}

.phone-info h4 {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}

.phone-info h4 span i {
  width: 46px;
  height: 46px;
  display: inline-block;
  text-align: center;
  line-height: 46px;
  background-color: #fff;
  border-radius: 50%;
  color: #ff3b2c;
  font-size: 22px;
  margin-left: 30px;
  margin-right: 15px;
}

.phone-info h4 span a {
  color: #fff;
  font-size: 15px;
  font-weight: 400;
}

form#contact .contact-dec {
  position: absolute;
  right: -166px;
  bottom: 0;
}

form#contact .contact-dec img {
  max-width: 178px;
}

form#contact {
  margin-left: 30px;
  position: relative;
  background-color: #fff;
  padding: 60px 30px;
  border-radius: 20px;
}

form#contact input {
  width: 100%;
  height: 46px;
  border-radius: 33px;
  background-color: #d1f3ff;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: #2a2a2a;
  /* padding: 0px 20px; */
  margin-bottom: 20px;
}

form#contact input::placeholder {
  color: #2a2a2a;
}

form#contact textarea {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  max-height: 180px;
  min-height: 140px;
  height: 140px;
  border-radius: 20px;
  background-color: #d1f3ff;
  border: none;
  outline: none;
  font-size: 15px;
  font-weight: 300;
  color: #2a2a2a;
  padding: 15px 20px;
  margin-bottom: 20px;
}

form#contact textarea::placeholder {
  color: #2a2a2a;
}

form#contact button {
  display: inline-block;
  background-color: #03a4ed;
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: capitalize;
  padding: 12px 25px;
  border-radius: 23px;
  letter-spacing: 0.25px;
  border: none;
  outline: none;
  transition: all 0.3s;
}

form#contact button:hover {
  background-color: #fe3f40;
}

/* 
---------------------------------------------
Footer Style
--------------------------------------------- 
*/

footer p {
  /* text-align: center; */
  /* margin: 30px 0px 45px 0px; */
}

footer p a {
  color: #fe3f40;
}

/* 
---------------------------------------------
responsive
--------------------------------------------- 
*/

@media (max-width: 1645px) {
  form#contact .contact-dec {
    display: none;
  }
}

@media (max-width: 992px) {
  .main-banner {
    padding-top: 196px;
  }

  .main-banner .left-content {
    margin-right: 0px;
    text-align: center;
    margin-bottom: 45px;
  }

  .main-banner:after {
    top: 76px;
    z-index: -1;
  }

  .main-banner .left-content form,
  .main-banner .left-content form input {
    width: 100% !important;
  }

  #about {
    margin-top: 0px;
  }

  .about-us {
    position: relative;
    background-image: none;
    padding: 0px;
  }

  .about-us .left-image {
    margin-right: 0px;
    position: absolute;
    bottom: -220px;
    right: 0;
  }

  .about-us .services {
    text-align: center;
  }

  .about-us .services .item {
    background: rgb(255, 77, 30);
    background: linear-gradient(
      105deg,
      rgba(255, 77, 30, 1) 0%,
      rgba(255, 44, 109, 1) 100%
    );
    padding: 30px;
    border-radius: 20px;
    display: inline-block;
  }

  .about-us .services .item .right-text {
    text-align: left;
  }

  .our-services {
    margin-top: 200px;
  }

  .our-services .left-image {
    margin-right: 0px;
    margin-bottom: 45px;
  }

  .our-services .section-heading h2,
  .our-services .section-heading p {
    margin-right: 0px;
    text-align: center;
  }

  .our-portfolio .section-heading h2 {
    margin: 0px 0px 80px 0px;
  }

  .our-portfolio .item {
    margin-bottom: 15px;
  }

  .our-blog {
    margin-top: 0px;
  }

  .our-blog .top-dec {
    display: none;
  }

  .our-blog .section-heading h2 {
    margin-right: 0px;
    text-align: center;
    margin-bottom: 45px;
  }

  .our-blog .left-image .info .inner-content {
    position: relative;
    margin-right: 0px;
  }

  .our-blog .left-image .info .main-blue-button {
    position: relative;
    bottom: 0px;
    margin-top: 30px;
  }

  .our-blog .left-image {
    margin-bottom: 45px;
  }

  .contact-us {
    margin-top: 60px;
    padding: 120px 0px;
  }

  .contact-us .section-heading {
    text-align: center;
  }

  form#contact {
    margin-left: 0px;
    margin-top: 30px;
  }

  form#contact .contact-dec {
    display: none;
  }

  footer p {
    margin: 15px 0px 30px 0px;
  }
}

@media (max-width: 767px) {
  .about-us .left-image {
    bottom: -280px;
  }

  .our-blog .right-list {
    margin-left: 0px;
  }

  .our-blog .right-list ul li {
    display: inline-block;
    margin-top: 0px;
    padding-top: 30px;
    border-top: 1px solid #eee;
  }

  .our-blog .right-list .left-content {
    margin-right: 0px;
    width: 100%;
    margin-bottom: 15px;
  }

  .our-blog .right-list .right-image,
  .our-blog .right-list .right-image img {
    width: 100%;
  }

  .phone-info h4 span {
    display: block;
    margin-top: 15px;
  }

  .phone-info h4 span i {
    margin-left: 0px;
  }
}

img.menu-logo {
  display: flex;
  top: 34px;
  margin-top: 26px;
  margin-right: 26px;
  width: 34px;
  height: 25px;
  left: 33px;
}

.header-area .main-nav .logo h4 {
  margin-left: 57px;
  margin-top: -48px;
  /* position: absolute;
      left: 33px; */
}

.add-cstspan {
  float: right;
}

.connect-logo-class {
  width: 30px;
  height: 30px;
}

.div_main {
  /* height: 736px; */
  height: 700px;
  width: 1920px;
  left: -864px;
  top: -699px;
  top: 1;
  margin-top: 98px;
}

.Looking_for_lawyer {
  background: #f4fefa;
}

.AdobeImgimage_class {
  /* max-height: 615px; */
  max-width: 568px;

  /* left: -608px;
   top: -601px; */
  /* border-radius: 16px; */
}

.green_btn {
  width: 140px;
  color: white;
  height: 50px;
  background-color: #208c84;
  border: 1px;
  font-size: 20px;
}

.button_arrow_handle {
  cursor: pointer;
  border: 1px;
}

.rightdiv_para {
  width: 500px;
  left: 200px;
  top: -370px;
  border-radius: nullpx;
  font: bold;
  /* font-size: 24px; */
  font-family: "Poppins";
}

.rightdiv_heading {
  height: 54px;
  width: 565px;
  left: 200px;
  top: -443px;
  border-radius: nullpx;
  font-size: 36px;
  font-family: "Poppins";
}

.right_div {
  height: 37px;
  width: 176px;
  left: 200px;
  top: -494px;
  border-radius: nullpx;
  margin-top: 60px;
  /* padding-top: 100px; */
}

span.button_arrow_handle {
  position: absolute;
  background-color: #3dc9a1;
  width: 177px;
  text-align: center;
  width: 52px;
  border-radius: 0px;
  padding: 10px;
}

.greenline_para {
  color: #3dc9a1;
  font-size: 22px;
  font-family: "Poppins";
  line-height: 36px;
}

.lift_div {
  text-align: center;
  /* position: unset; */
  /* left: 608px; */
  /* margin: 60px; */
  /* padding-left: 170px; */
}

@media (max-width: 375px) {
  .right_div {
    height: 0px !important;
    left: 200px;
    padding-top: 0px;
    top: -494px;
    margin-top: 0px;
    margin-left: 16px;
  }

  .rightdiv_heading {
    height: 54px;
    width: 565px;
    left: 200px;
    top: -443px;
    border-radius: nullpx;
    font-size: 24px;
    font-family: "Poppins";
  }

  .AdobeImgimage_class {
    max-width: 218px;
    max-height: 187px;
  }

  .rightdiv_para {
    width: 314px;
    left: 200px;
    top: -370px;
    border-radius: nullpx;
    font: bold;
    font-size: 16px;
    font-family: "Poppins";
  }

  .greenline_para {
    font-size: 15px;
  }
}

@media (max-width: 320px) {
  .right_div {
    height: 0px !important;
    left: 200px;
    padding-top: 0px;
    top: -494px;
    border-radius: nullpx;
    margin: 11px;
    margin-top: 0px;
  }

  .rightdiv_heading {
    height: 54px;
    width: 565px;
    left: 200px;
    top: -443px;
    border-radius: nullpx;
    font-size: 24px;
    font-family: "Poppins";
  }

  .AdobeImgimage_class {
    max-width: 218px;
    max-height: 187px;
  }

  .rightdiv_para {
    width: 314px;
    left: 200px;
    top: -370px;
    border-radius: nullpx;
    font: bold;
    font-size: 16px;
    font-family: "Poppins";
  }

  .greenline_para {
    font-size: 15px;
  }
}

.circle_arrow_img {
  width: 43px;
  height: 43px;
}

.row_image_class {
  width: 43px;
}

.findleagal_con {
  height: 1065px;
  width: 1920px;
  background: #e8f8f2;
}

.custm-span-connact {
  /* font-weight: 500;
  font-size: 22px; */
}

.UserMen_img {
  height: 60px;
  padding: 16px;
  width: 62px;
}

.heading-leagal-service {
  color: #3dc9a1;
  font-weight: 400;
  font-size: 22px;
  font-style: "Poppins";
}

.second_ehading_lagal_srvice {
  font-size: 48px;
  line-height: 72px;
  color: #208c84;
  font-style: "poppins";
}

.col-sm-7.right_cstm_legal_div {
  margin-top: 200px;
}

.col-sm-4.one_extra:hover {
  background: #c6eee2;
}

.legal_consultancy {
  height: 900px;
  width: auto;
  background: #e8f8f2;
}

.read_more {
  color: #3dc9a1;
}

/* Find legal Services section media query */

@media (max-width: 320px) {
  .header-area {
    margin-left: -41px;
  }

  .new_mobile_class span {
    font-size: 30px;
  }
}

.three_steps {
  display: flex;
  justify-content: space-around;
}

.cstm3_div {
  justify-content: center;
}

.discounts-section {
  padding: 40px 216px 58px;
  background-color: #f4fefa;
}

.mmy-question {
  padding: 40px 216px 58px;
  background-color: #e8f8f2;
}

.cstm-container-formy-question {
  background-color: #e8f8f2;
}

.discount-heading .main-button {
  transform-origin: left top;
}

.green-heading-two {
  color: #208c84;
}

.discounts-img {
  width: 80%;
  /* height: 70%; */
  object-fit: cover;
  /* float: right; */
}

.green-span-featurs-question {
  width: 111px;
  height: 37px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 167.5%;
  letter-spacing: 0.06em;
  color: #208c84;
}

.user-data {
  /* width: 543px; */
  /* height: 78px; */

  background: #e8f8f2;
  border-radius: 20px !important;
}

#kandda {
  padding: 50px;
}

.new-question-card {
  width: 40rem;
  padding: 18px;
  background-color: pink;
  border-radius: 25px !important;
}

.first-container-para-m1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #191919;
}

.first-container-para-m2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 167.5%;
  color: #191919;
}

.first-container-heading-m {
  color: #208c84;
  font-size: 40px;
  font-family: "Poppins";
  font-style: normal;
}

@media (max-width: 575.98px) {
  .hi_class {
    padding: 0px;
  }
  .prov-services_conatiner-ma {
    background-color: #e5eaed;
    height: 1000px;
    padding: 0px !important;
  }
  .Are_Yousdf {
    max-width: 50% !important;
    height: 50% !important;
  }

  .rightdiv_heading {
    height: 54px;
    width: 565px;
    left: 200px;
    top: -443px;
    border-radius: nullpx;
    font-size: 24px;
    font-family: "Poppins";
  }
  .connect-mobile {
    /* margin-top: 271px; */
    /* width: 304px !important; */
    float: right;
  }

  .col-sm-7.right_cstm_legal_div {
    /* width: 93%; */
    margin-top: 60px;
    margin-left: 13px;
    text-align: left;
  }

  section.legal_consultancy {
    height: 1763px;
  }

  .first-container-test {
    font-size: 24px !important;
  }

  .hero_section_first {
    text-align: left;
    height: 1380px !important;
  }

  .btn_hero_long {
    margin-bottom: 49px;
    width: 138px !important;
  }

  .we_coonect_section {
    height: 2500px !important;
  }

  .discounts-section {
    padding: 84px 16px 40px;
  }

  .mmy-question {
    padding: 84px 16px 40px;
  }

  #kandda {
    margin: 24px 10px 10px;
    padding: 8px !important;
  }
}

@media (max-width: 1399.98px) {
  .discounts-section {
    padding: 70px 95px 40px;
  }

  .mmy-question {
    padding: 70px 95px 40px;
  }
}

@media (max-width: 1199.98px) {
  .discounts-section {
    padding: 64px 16px 40px;
  }

  .mmy-question {
    padding: 64px 16px 40px;
  }
}

@media (max-width: 768px) {
  .div_main {
    padding: 10px;
  }
  .rightdiv_heading {
    height: 54px;
    width: 565px;
    left: 200px;
    top: -443px;
    border-radius: nullpx;
    font-size: 24px;
    font-family: "Poppins";
  }
  .reorder {
    display: flex;
    flex-direction: column;
  }

  #side_panel {
    order: 2;
  }

  .connect-mobile {
    margin-top: 271px;
    width: 600px;
    float: right;
  }
}

@media (max-width: 1326px) {
  .connect-mobile {
    margin-top: 271px;
    width: 100% !important;
    float: right;
  }
}

@media (max-width: 1920px) {
  .connect-mobile {
    margin-top: 271px;
    width: 61%;
    float: right;
  }
}

.prov-services_conatiner-ma {
  background-color: #e5eaed;
  height: 700px;
  padding: 40px 216px 58px;
}
.Are_Yousdf {
  max-width: 100% !important;
}
.hi_class {
  padding: 10px;
}

.hi_class {
  padding: 59px 52px 38px;
  background: white;
}

.connect-mobile {
  margin-top: 271px;
  /* width: 50%; */
  float: right;
}

.img-wrap {
  text-align: center;
}
.img-wrap-data {
  width: 300px;
  margin: 0 auto;
}
.sub-header {
  padding: 10px 20px;
  color: #208c84;
  background: #f2f2f2;
}
.img-wrap {
  margin-right: 10px;
}

.header {
  background-color: #fff;
  padding: 15px;
}
.premium-badge svg p {
  margin: 0;
  font-size: 10px;
  line-height: 12px;
  color: #ffb743;
}

.premium-badge {
  text-align: center;
  color: #ffb743;
}

.premium-badge svg {
  width: 25px;
  height: 25px;
}

.first-card-h4-p {
  margin: 0;
}
.first-card-h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
}
.inner-card-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 18px 22px;
  border-bottom: 1px solid #d9d9d9;
  transition: all ease 0.3s;
}
.online {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: green;
  border: 1.5px solid #fff;
}
.user-info {
  display: flex;
  align-items: center;
  gap: 12px;
}
.avatar {
  width: 53px;
  height: 53px;
}
/* :hover {
  transform: scale(1.25);
  box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
  border-radius: 12px;
} */
.mobile {
  background: #ffffff;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 30px !important;
  min-width: 145px;
}
.second-p {
  display: flex;
  align-items: center;
}
.second-span {
  margin-right: 5px;
}
.first-p {
  color: #208c84;
  margin: 0;
}
.bottom-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.img-data {
  width: 35px;
  height: 35px;
}
.inner-card-wrap:hover {
  transform: scale(1.25);
  box-shadow: 0px 10px 10px rgb(0 0 0 / 10%);
  border-radius: 12px;
}
